import { EventStatuses } from '../types';
import { clamp } from '../helpers/math';
import { mergeColors } from './color';

export type IntervalUnit = 'days' | 'minutes';

export interface IntervalFunctions {
  get: () => number;
  set: (date: number) => number;
  display: (date: Date, type: 'min' | 'max') => string;
}

export const intervalsFunctions: Record<IntervalUnit, IntervalFunctions> = {
  days: {
    get: Date.prototype.getDate,
    set: Date.prototype.setDate,
    display: (date: Date, type: 'min' | 'max') => {
      let displayDate: Date;

      if (type === 'max') {
        displayDate = new Date(date);
        displayDate.setDate(displayDate.getDate() - 1);
      }
      else {
        displayDate = date;
      }

      return displayDate.toLocaleDateString();
    },
  },
  minutes: {
    get: Date.prototype.getMinutes,
    set: Date.prototype.setMinutes,
    display: (date: Date, type: 'min' | 'max') => {
      let displayDate: Date;

      if (type === 'max') {
        displayDate = new Date(date);
        displayDate.setMinutes(displayDate.getMinutes() - 1);
      }
      else {
        displayDate = date;
      }

      return displayDate.toLocaleTimeString(undefined, { hour: '2-digit', minute:'2-digit' });
    },
  },
};

export const timeSince = (diffMs: number) => {

  let seconds = Math.floor(diffMs / 1000);

  let interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + "d";
  }

  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + "h";
  }

  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + "m";
  }

  return Math.floor(seconds) + "s";
};

const OUT_OF_DATE_MS_LIMIT = 5 * 60 * 1000;

export const renderOutOfDateInfos = (infoDate: Date | null | undefined) => {
  if (!infoDate) {
    return null;
  }

  const now = new Date();

  const diff = now.getTime() - infoDate.getTime();

  if (diff < OUT_OF_DATE_MS_LIMIT) {
    return null;
  }

  return ` (${timeSince(diff)} ago)`;
};

export const colorScheme: Record<EventStatuses, string> = {
  [EventStatuses.Undefined]: '#9E9E9E',
  [EventStatuses.Operational]: '#2AB72E',
  [EventStatuses.MajorOutage]: '#F44336',
  [EventStatuses.PartialOutage]: '#FFC107',
  [EventStatuses.DegradedPerformance]: '#2196F3',
  [EventStatuses.Warning]: '#FFEA07',
  [EventStatuses.Maintenance]: '#673AB7',
};

export const getFadeColor = (outagePercent: number) => {
  if (outagePercent === 0) {
    return colorScheme[EventStatuses.Operational];
  }

  outagePercent = clamp(outagePercent, 0.0125, 0.2);

  return mergeColors(
    colorScheme[EventStatuses.Operational],
    colorScheme[EventStatuses.MajorOutage],
    Math.pow(outagePercent / 0.2, 0.25),
    [{ t: 0.5, color: '#BDC51B' }],
  );
};

export const statusPrecedence: Record<EventStatuses, number> = {
  [EventStatuses.Undefined]: 0,
  [EventStatuses.Operational]: 1,
  [EventStatuses.MajorOutage]: 5,
  [EventStatuses.PartialOutage]: 4,
  [EventStatuses.DegradedPerformance]: 2,
  [EventStatuses.Warning]: 3,
  [EventStatuses.Maintenance]: 6,
};

export const eventsStatusMapping: Record<EventStatuses, EventStatuses> = {
  [EventStatuses.Undefined]: EventStatuses.Undefined,
  [EventStatuses.Operational]: EventStatuses.Operational,
  [EventStatuses.DegradedPerformance]: EventStatuses.Operational,
  [EventStatuses.PartialOutage]: EventStatuses.PartialOutage,
  [EventStatuses.MajorOutage]: EventStatuses.MajorOutage,
  [EventStatuses.Warning]: EventStatuses.Warning,
  [EventStatuses.Maintenance]: EventStatuses.Maintenance,
};

export const statusUp: Record<EventStatuses, boolean> = {
  [EventStatuses.Undefined]: false,
  [EventStatuses.Operational]: true,
  [EventStatuses.MajorOutage]: false,
  [EventStatuses.PartialOutage]: true,
  [EventStatuses.DegradedPerformance]: true,
  [EventStatuses.Warning]: true,
  [EventStatuses.Maintenance]: false,
};

export const statusAll: Record<EventStatuses, boolean> = {
  [EventStatuses.Undefined]: false,
  [EventStatuses.Operational]: true,
  [EventStatuses.MajorOutage]: true,
  [EventStatuses.PartialOutage]: true,
  [EventStatuses.DegradedPerformance]: true,
  [EventStatuses.Warning]: true,
  [EventStatuses.Maintenance]: true,
};

export const statusForceIssue: Record<EventStatuses, boolean> = {
  [EventStatuses.Undefined]: false,
  [EventStatuses.Operational]: false,
  [EventStatuses.MajorOutage]: true,
  [EventStatuses.PartialOutage]: true,
  [EventStatuses.DegradedPerformance]: false,
  [EventStatuses.Warning]: true,
  [EventStatuses.Maintenance]: true,
};

export const statusOutage: Record<EventStatuses, boolean> = {
  [EventStatuses.Undefined]: false,
  [EventStatuses.Operational]: false,
  [EventStatuses.MajorOutage]: true,
  [EventStatuses.PartialOutage]: true,
  [EventStatuses.DegradedPerformance]: false,
  [EventStatuses.Warning]: false,
  [EventStatuses.Maintenance]: false,
};

export const statusDisplay: Record<EventStatuses, string> = {
  [EventStatuses.Undefined]: 'Undefined',
  [EventStatuses.Operational]: 'Operational',
  [EventStatuses.MajorOutage]: 'Major outage',
  [EventStatuses.PartialOutage]: 'Partial outage',
  [EventStatuses.DegradedPerformance]: 'Degraded performance',
  [EventStatuses.Warning]: 'Warning',
  [EventStatuses.Maintenance]: 'Maintenance',
};
